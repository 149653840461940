

import { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import { BasicContainer } from 'components/content-blocks/basic-container';
import CustomerReviews from 'components/content-blocks/customer-reviews/customer-reviews';
import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import MinimalBlogArticles from 'components/content-blocks/minimal-blog-articles';
import ProductVideo from 'components/content-blocks/product-video';
import MoreIndustries from 'components/globals/more-industries';
import Yoast from 'components/globals/yoast';
import { HeroFeatures } from 'components/hero/features';
import Slider from 'components/sliders/industry-reviews';

import 'pages/solutions/styles/solutions.scss';
import 'templates/styles/industries.scss';

export default function LaborForecastingSoftware() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "labor-forecasting-software" } }) {
        edges {
          node {
           
            wordpress_id
            yoast_head_json {
              title
              description
              canonical
              og_title
              og_description
              og_image {
                url {
                source_url
                }
              }
              og_url
              og_type
              twitter_title
              twitter_description
              twitter_image {
                source_url
              }
            }
            acf {
              hero {
                title
                sub_title
                copy
                signup {
                  footer_signup_title
                }
                background_desktop_image {
                  source_url
                  alt_text
                }
              }
              video_block_1 {
                video_id
                image_desktop {
                  source_url
                }
                image_mobile {
                  source_url
                }
              }
              media_object {
                title
                sub_title
                body
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                button_text
                button_url
                schedule_demo
              }
              industry_reviews {
                title
                sub_title
                reviews {
                  type
                  title
                  top_content
                  name
                  position
                  quote
                  bottom_content
                  stats {
                    stat
                    text_label
                  }
                  logo {
                    alt_text
                    source_url
                  }
                  customer_photo {
                    alt_text
                    source_url
                  }
                  products_used {
                    product_name
                    product_icon {
                      url {
                        alt_text
                        source_url
                      }
                    }
                  }
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                }
                card {
                  title
                  copy
                }
              }
              cards_2 {
                header {
                  title
                }
                card {
                  header
                  title
                  link_text
                  link_url
                  image {
                    source_url
                    alt_text
                  }
                }
              }
              customer_reviews {
                title
                sub_title
                customer {
                  name
                  company
                  quote
                  image {
                    source_url
                  }
                }
              }
              basic_title_subtitle_copy_image {
                title
                copy
                button_text
              }
              minimal_blog_articles {
                title
                more_link
                article {
                  link
                  title
                  image {
                    source_url
                    alt_text
                  }
                }
              }
              faq {
                question
                answer
              }
              footer_signup {
                header
                button_text
              }
            }
          }
        }  
      }
      allWordpressAcfOptions {
        edges {
          node {
            options {
              structured_data {
                faq {
                  labor_forecasting_software
                }
              }
              industries {
                industries {
                  url
                  title
                  icon {
                    alt_text
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const footerSignup = pageContext.footer_signup;
  const videoBlock = pageContext.video_block_1;
  const mediaObject = pageContext.media_object;
  const industryReviews = pageContext.industry_reviews;
  const basicTitleSubtitleCopyImage = pageContext.basic_title_subtitle_copy_image;
  const articles = pageContext.minimal_blog_articles;
  const cards1 = pageContext.cards_1;
  const cards2 = pageContext.cards_2;
  const customerReviews = pageContext.customer_reviews;
  const moreIndustries = data.allWordpressAcfOptions.edges[0].node.options.industries;
  const faqStructuredData = data.allWordpressAcfOptions.edges[0].node.options.structured_data.faq.labor_forecasting_software; 
  const faq = pageContext.faq;

  function renderFooterHeader() {
    const header = footerSignup.header.split('. ')[0] || '';
    const cta = footerSignup.header.split('. ')[1] || '';
    return (
      <Fragment><span>{header}.</span> {cta}</Fragment>
    );
  }

  return (
    <Layout
      faq={ faq }
      faqStructuredData={ faqStructuredData }
      footerSignupHeader={ renderFooterHeader() }
      signupModalLaunch={ footerSignup.button_text }
      modalTitle={ hero.signup.modal_title }
      legalText={ hero.signup.legal_text }
    >
      <div className="solutions-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
        <HeroFeatures
          { ...hero }
          uniqueId="timeClockFeature"
          section="featuresTimeClockHero"
        />
        <ProductVideo { ...videoBlock } />
        <MediaObject { ...mediaObject[0] } schedule_demo_text={ mediaObject[0].button_text } />
        <MediaObject { ...mediaObject[1] } schedule_demo_text={ mediaObject[1].button_text } className="reverse" />

        {industryReviews &&  (
          <section className="section industry-reviews-section">
            <div className="container">
              <div className="review-header">
                <h4 className="subtitle is-spaced has-text-centered">
                  {industryReviews.sub_title}
                </h4>
                <h2 className="title has-text-centered">
                  {industryReviews.title}
                </h2>
              </div>
              <Slider
                card={ industryReviews.reviews }
                hasOneSlideVisible
                startingIndex={ 0 }
                activeIndex={ 0 }
              />
            </div>
          </section>
        )}
        <HighlightCards { ...cards1 } className="background-green understaffed-section" hasTitle hasCopy />

        <MediaObject { ...mediaObject[2] } schedule_demo_text={ mediaObject[2].button_text } />
        <MediaObject { ...mediaObject[3] } schedule_demo_text={ mediaObject[3].button_text } className="reverse" />
        <CustomerReviews { ...customerReviews } hasAwards />
        <BasicContainer { ...basicTitleSubtitleCopyImage } />
        <MoreIndustries { ...moreIndustries } copy="Industries served" />
        <MinimalBlogArticles { ...articles } />
        <HighlightCards className="features-container" hasTitle hasImage hasHeader hasCta { ...cards2 } />
        
      </div>
    </Layout>
  );
}
